export const getLocationData = (t, window) => {
  const locationData = {};
  try {
    const location = t.props.location || window.location;
    if (location) {
      const fields = ['href', 'protocol', 'host', 'hostname', 'port', 'pathname', 'search', 'hash'];
      fields.forEach(field => {
        if (location[field]) {
          locationData[field] = location[field];
        }
      });
      locationData.hash = location.hash && location.hash.indexOf('?') > -1 ? location.hash.substring(0, location.hash.indexOf('?')) : location.hash;
      let search = location.hash && location.hash.indexOf('?') > -1 ? location.hash.substring(location.hash.indexOf('?')) : location.search;
      locationData.search = search;
      while (search.startsWith('?')) {
        search = search.substring(1);
      }
      search = search.split('&');
      const params = {};
      search.forEach(param => {
        if (param && param.indexOf('=') > -1) {
          const key = param.substring(0, param.indexOf('='));
          const value = decodeURIComponent(param.substring(param.indexOf('=') + 1, param.length));
          params[key] = value;
        }
      });
      locationData.params = params;
    }
  } catch (err) {}
  return locationData;
};

export const capitalize = s => {
  if (typeof s !== 'string')
    return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getPathHeaderMeta = path => {
  path = path ? path.replace('/th', '').replace('/en', '') : path;
  path = path.startsWith('/blog') ? '/blog' : path;
  path = !path ? '/' : path;
  let title = process.env.REACT_APP_DEFAULT_TITLE;
  let description = process.env.REACT_APP_DEFAULT_DESCRIPTION;
  let url = process.env.REACT_APP_WEBSITE_URL;
  switch (path) {
    case '/pricing':
      title = `${process.env.REACT_APP_APP_NAME} - Pricing`;
      url = `${url}${path}`;
      break;
    case '/terms-of-service':
      title = `${process.env.REACT_APP_APP_NAME} - Terms of Service`;
      url = `${url}${path}`;
      break;
    case '/features':
      title = `${process.env.REACT_APP_APP_NAME} - Features`;
      url = `${url}${path}`;
      break;
    case '/pms':
      title = `${process.env.REACT_APP_APP_NAME} - PMS`;
      url = `${url}${path}`;
      break;
    case '/channel-manager':
      title = `${process.env.REACT_APP_APP_NAME} - Channel Manager`;
      url = `${url}${path}`;
      break;
    case '/direct-booking':
      title = `${process.env.REACT_APP_APP_NAME} - Direct Booking`;
      url = `${url}${path}`;
      break;
    case '/blog':
      title = `${process.env.REACT_APP_APP_NAME} - Blog`;
      url = `${url}${path}`;
      break;
    case '/docs':
      title = `${process.env.REACT_APP_APP_NAME} - Docs`;
      url = `${url}${path}`;
      break;
    default:
      break;
  }
  return path ?
    { browser_title: `${path === '/' ? process.env.REACT_APP_DEFAULT_BROWSER_TITLE : path === '/pricing' ? 'Pricing' : path === '/terms-of-service' ? 'Terms of Service' : path === '/pms' ? 'PMS' : path === '/channel-manager' ? 'Channel Manager' : path === '/direct-booking' ? 'Direct Booking' : path === '/404' ? 'Page Not Found' : capitalize(path.slice(1))} | ${process.env.REACT_APP_APP_NAME}`, title, description, url }
    :
    null;
};
