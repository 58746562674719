import React from 'react';
import { withRouter } from 'react-router-dom';
// import MessengerCustomerChat from 'react-messenger-customer-chat';
import ScrollUpButton from 'react-scroll-up-button';
import FeatherIcon from 'feather-icons-react';
import { getLocationData } from '../../utils';

const Topbar = React.lazy(() => import('./Topbar'));
const Footer = React.lazy(() => import('./Footer'));

const CustomDot = () => {
  return (
    <i><FeatherIcon icon="arrow-up" className="icons" /></i>
  );
};

class Layout extends React.Component {
  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const locationData = getLocationData(this, window);
    let lang = locationData.pathname.startsWith('/th') ? 'th' : locationData.pathname.startsWith('/en') ? 'en' : '';
    if (!lang) {
      const browserLocale = require('browser-locale')();
      lang = browserLocale && (browserLocale.toLowerCase() === 'th-th' || browserLocale.toLowerCase() === 'th') ? 'th' : 'en';
      lang = 'th';
    }
    const { children } = this.props;
    return (
      <React.Fragment>
        <React.Suspense fallback={this.Loader()}>
          <Topbar lang={lang} />
          {children}
          <Footer lang={lang} />
          <ScrollUpButton ContainerClassName="classForContainer" style={{ height: 36, width: 36, marginBottom: '3.75rem', marginRight: '-.5rem' }} TransitionClassName="classForTransition">
            <CustomDot />
          </ScrollUpButton>
          {/*children && (
            <MessengerCustomerChat
              pageId={process.env.REACT_APP_FACEBOOK_PAGE_ID}
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              loggedInGreeting={process.env.REACT_APP_FACEBOOK_GREETING}
              loggedOutGreeting={process.env.REACT_APP_FACEBOOK_GREETING}
            />
          )*/}
        </React.Suspense>
      </React.Fragment>
    );
  }
}

export default withRouter(Layout);
