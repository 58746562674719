import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { createBrowserHistory } from 'history';
const history = createBrowserHistory();
const root = document.getElementById('root');
if (root) {
	// const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
	// if (path) {
	// 	history.replace(path);
	// }
	const path = (/\?!(\/.*)$/.exec(window.location.search) || [])[1];
	if (path) {
		history.replace(`${path}${window.location.hash}`);
	}
}
ReactDOM.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>,
	root
);

// ReactDOM.render(
// 	<BrowserRouter>
// 		<App />
// 	</BrowserRouter>,
// 	document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
