import React from 'react';

const Index = React.lazy(() => import('./pages/Home/index'));
const PagePricing = React.lazy(() => import('./pages/Pricing/index'));
const PageTerms = React.lazy(() => import('./pages/Pages/Utility/PageTerms'));
const PageFeatures = React.lazy(() => import('./pages/FeaturesIndex/index'));
const PagePMS = React.lazy(() => import('./pages/PMS/index'));
const PageChannelManager = React.lazy(() => import('./pages/ChannelManager/index'));
const PageDirectBooking = React.lazy(() => import('./pages/DirectBooking/index'));
const PageBlog = React.lazy(() => import('./pages/Blog/index'));
const PageDocs = React.lazy(() => import('./pages/Docs/index'));
const PageError = React.lazy(() => import('./pages/Pages/Special/PageError'));

const appRoutes = ['login', 'register', 'forgot', 'dashboard', 'profile', 'change-password', 'payment', 'document-info', 'settings', 'friends', 'referrals', 'my-articles', 'upcoming', 'reservations', 'reservations-table', 'occupancy-rate', 'reservations-search', 'rates', 'channels', 'booking-engine', 'guests', 'units', 'docs-history'];
const routes = [
  { path: '/', component: Index, exact: true },
  { path: '/th', component: Index, exact: true },
  { path: '/en', component: Index, exact: true },
  { path: '/pricing', component: PagePricing, exact: true },
  { path: '/th/pricing', component: PagePricing, exact: true },
  { path: '/en/pricing', component: PagePricing, exact: true },
  { path: '/terms-of-service', component: PageTerms, exact: true },
  { path: '/features', component: PageFeatures, exact: true },
  { path: '/th/features', component: PageFeatures, exact: true },
  { path: '/en/features', component: PageFeatures, exact: true },
  { path: '/pms', component: PagePMS, exact: true },
  { path: '/th/pms', component: PagePMS, exact: true },
  { path: '/en/pms', component: PagePMS, exact: true },
  { path: '/channel-manager', component: PageChannelManager, exact: true },
  { path: '/th/channel-manager', component: PageChannelManager, exact: true },
  { path: '/en/channel-manager', component: PageChannelManager, exact: true },
  { path: '/direct-booking', component: PageDirectBooking, exact: true },
  { path: '/th/direct-booking', component: PageDirectBooking, exact: true },
  { path: '/en/direct-booking', component: PageDirectBooking, exact: true },
  { path: '/blog', component: PageBlog },
  { path: '/th/blog', component: PageBlog },
  { path: '/en/blog', component: PageBlog },
  { path: '/docs', component: PageDocs, exact: true },
  { path: '/th/docs', component: PageDocs, exact: true },
  { path: '/en/docs', component: PageDocs, exact: true },
  { path: '/404', component: PageError, isWithoutLayout: true, exact: true },
];
appRoutes.forEach(route => routes.push({ path: `/${route}`, exact: true, redirectPath: `${process.env.REACT_APP_HOST_URL}/${route}` }));
routes.push({ component: PageError, isWithoutLayout: true, exact: false });
export default routes;
