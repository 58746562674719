import React from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter, Redirect } from 'react-router-dom';
import Layout from './components/Layout/';
import routes from './routes';
import './Apps.scss';
import './assets/css/materialdesignicons.min.css';
import './assets/css/colors/default.css';
import './assets/css/app.css';

const withLayout = WrappedComponent => {
  return class extends React.Component {  
    render() {
      return (
        <Layout>
          <WrappedComponent />
        </Layout>
      );
    }
  };
};

const withLayoutNotComponent = WrappedComponent => {
  return (
    <Layout>
      <WrappedComponent />
    </Layout>
  );
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  }

  renderLayout = (e, page) => {
    const { locationKey } = this.state;
    if (e && locationKey !== e.location.key && (e.history.action === 'REPLACE' || e.history.action === 'POP')) {
      this.setState({ locationKey: e.location.key });
      return null;
    }
    return page;
  }

  render() {
    return (
      <React.Fragment>  
        <Router>
          <React.Suspense fallback={this.Loader()}>
            <Switch>
              <Route
                path={['/http:', '/https:']}
                component={props => {
                  window.location.replace(props.location.pathname.substr(1));
                  return null;
                }}
              />
              {routes.map((route, idx) =>
                route.redirectPath ?
                  <Route path={route.path} exact={route.exact} render={() => { return (<Redirect to={route.redirectPath} />); }} key={idx} />
                  :
                  route.isWithoutLayout ?
                    route.reload ?
                      <Route path={route.path} exact={route.exact} component={e => this.renderLayout(e, route.component)} key={idx} />
                      :
                      <Route path={route.path} exact={route.exact} component={route.component} key={idx} />
                    :
                    route.reload ?
                      <Route path={route.path} exact={route.exact} component={e => this.renderLayout(e, withLayoutNotComponent(route.component))} key={idx} />
                      :
                      <Route path={route.path} exact={route.exact} component={withLayout(route.component)} key={idx} />
              )}
            </Switch>
          </React.Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
